<span>
  <mat-form-field appearance="fill">
    <mat-select [(value)]="selectedSize"(selectionChange)="onSizeChange()">
      <mat-option *ngFor="let size of sizeOptions" [value]="size">
        {{ "InspectionResult.Tabs.GalleryOverview.Size." + size | i18next }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <loading *ngIf="isLoading"></loading>
  <img
    id="image-{{ inspectionItemImageDetails.articleIdentifier }}-{{ inspectionItemImageDetails.failureReasonId }}"
    [src]="getRightImage(inspectionItemImageDetails.inspectionItemFailureReasonId)"
    class="mr-6 image-container"
    (load)="onImageLoad()"
    (click)="
      openImageModal(
        inspectionItemImageDetails.inspectionItemName,
        inspectionItemImageDetails.failureReasonId,
        inspectionItemImageDetails.inspectionItemFailureReasonId
      )
    "
  />
</span>
