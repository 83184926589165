import { Component } from "@angular/core";
import { TranslationService } from "src/app/services/translation.service";

@Component({
  selector: "app-language-button",
  templateUrl: "./language-button.component.html",
  styleUrls: ["./language-button.component.scss"],
})
export class LanguageButtonComponent {
  selectedLanguage = this.translationService.selectedLanguage;

  constructor(private translationService: TranslationService) {}

  changeLanguage(lang: string | null) {
    this.translationService.changeLanguage(lang);
  }

  public get languages() {
    return [
      "BG",
      "CS",
      "DA",
      "DE",
      "EL",
      "EN",
      "EN-US",
      "ES",
      "ET",
      "FI",
      "FR",
      "HR",
      "HU",
      "IT",
      "LT",
      "LV",
      "NO",
      "NL",
      "PL",
      "PT",
      "RO",
      "SK",
      "SL",
      "SR",
      "SV",
      "TR",
      "UK",
    ];
  }
}
