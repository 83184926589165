import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getLocalizedDateFormat } from 'src/app/constants/date-format-options';
import { InspectionResultDetailsDto, InspectionResultModel } from 'src/app/models/inspection-result';
import { TestDetailsPdfPreviewDto } from 'src/app/models/test-details-pdf-preview.model';
import { ExportPdfService } from 'src/app/services/export-pdf.service';

@Component({
  selector: 'app-test-details-pdf-preview',
  templateUrl: './test-details-pdf-preview.component.html',
  styleUrls: ['./test-details-pdf-preview.component.scss']
})
export class TestDetailsPdfPreviewComponent implements OnInit {
  inspectionResult!: InspectionResultModel;
  inspectionResultDetails!: InspectionResultDetailsDto;

  isLoading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: TestDetailsPdfPreviewDto,
    private exportPdfService: ExportPdfService,
    public dialogRef: MatDialogRef<TestDetailsPdfPreviewComponent>) {
  }

  ngOnInit(): void {
    this.inspectionResult = this.data.inspectionResult;
    this.inspectionResultDetails = this.data.inspectionResultDetails;
  }

  getDateFormat(date: string) {
    return getLocalizedDateFormat(date);
  }

  downloadAsPdf() {
    this.isLoading = true;
    this.exportPdfService.testDetailsGeneratePdf(this.hideLoading.bind(this), this.inspectionResultDetails);
  }

  hideLoading() {
    this.isLoading = false;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
