import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";
import { InspectionItem } from "src/app/models/inspection-item";
import { ItemsService } from "src/app/services/items.service";
import { articleQuantityValidator, articleValidatorFn, getInspectionGroupValidators } from "./focus-group.validators";
import { FocusItemDto } from "src/app/models/focus-item";
import { FocusItemsService } from "src/app/services/focus-items.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-focus-item-dialog",
  templateUrl: "./focus-item-dialog.component.html",
  styleUrls: ["./focus-item-dialog.component.scss"],
})
export class FocusItemDialogComponent implements OnInit {
  @Output() getInspectionEvent = new EventEmitter<string>();
  isLoading = false;
  loadNextClicks = 1;
  inspectionsResult!: InspectionItem[];
  totalStockQuantity?: number;
  availableFocusItems = 0;
  availableStockQuantity?: number;
  previousSelection?: InspectionItem;
  areTotalPiecesLoaded = false;
  isStockQuantityExceeded = false;
  inspectionGroupForm: FormGroup = new FormGroup({});

  constructor(
    private itemsService: ItemsService,
    private focusItemsService: FocusItemsService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FocusItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      availablePieces: number,
      inspectionId: string,
    }
  ) { }

  ngOnInit(): void {
    this.inspectionGroupForm = this.fb.group(getInspectionGroupValidators(this.data.availablePieces));
    this.watchFormFieldValueChange("article");
    this.watchFormFieldValueChange("quantity");
  }

  onClose(): void {
    this.dialogRef.close();
  }

  searchValue(data: { searchText: string; inspectionId: string; currentPage: number }) {
    this.isLoading = true;
    this.itemsService.getItems(data.inspectionId, data.searchText, data.currentPage).subscribe({
      next: (result) => {
        this.isLoading = false;
        const hasResults = result && result.items.length > 0;
        const emptyFocusItems = [{ articleIdentifier: "", inspectionItemId: "", itemId: "", name: "", quantity: 0 }];
        this.availableFocusItems = result.totalNumberOfItems;
        this.inspectionsResult = hasResults ? result.items : emptyFocusItems;
        this.areTotalPiecesLoaded = this.availableFocusItems === this.inspectionsResult.length;
      },
      error: () => {
        this.isLoading = false;
        this.inspectionsResult = [];
      },
    });
  }

  addFocusItem() {
    if (this.inspectionGroupForm && this.inspectionGroupForm.valid) {
      const focusItems = this.inspectionGroupForm.controls["article"].value as InspectionItem;
      const quantity = this.inspectionGroupForm.controls["quantity"].value as number;
      const focusItemPayload: FocusItemDto = {
        itemId: focusItems.itemId,
        quantity: quantity,
      };
      if (focusItemPayload.quantity > this.data.availablePieces) {
        this.isStockQuantityExceeded = true;
        return;
      }

      this.isLoading = true;
      this.focusItemsService
        .saveFocusItem({
          ...focusItemPayload,
          inspectionId: this.data.inspectionId,
        })
        .subscribe({
          next: (data) => {
            const focusItemResponse = JSON.parse(data);
            if (!focusItemResponse.isSuccess) {
              this.availableStockQuantity = focusItemResponse.stockQuantity;
              this.isStockQuantityExceeded = this.availableStockQuantity ? true : false;
              this.updateTotalStockQuantityAndValidate();
              this.getArticleQuantityValidation(true);
              this.isLoading = false;
            } else {
              this.availableStockQuantity = undefined;
              this.updateTotalStockQuantityAndValidate();
              this.getArticleQuantityValidation();
              this.getInspectionEvent.emit(this.data.inspectionId as string);
              this.dialogRef.close();
            }
          },
          error: () => { },
        });
    }
  }

  watchFormFieldValueChange(fieldName: string) {
    this.inspectionGroupForm.controls[fieldName].valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((value) => {
          this.isStockQuantityExceeded = this.availableStockQuantity ? this.availableStockQuantity < value : false;
          if (fieldName === "article" && value && value.length > 2) {
            this.searchValue({ searchText: value, inspectionId: this.data.inspectionId, currentPage: 0 });
          } else {
            const isQuantityValueExceeded =
              (this.availableStockQuantity && this.totalStockQuantity && value > this.totalStockQuantity) ||
              this.isStockQuantityExceeded;
            return isQuantityValueExceeded
              ? this.getArticleQuantityValidation(!!this.totalStockQuantity)
              : this.getArticleQuantityValidation();
          }
        })
      )
      .subscribe(() => { });
  }

  onFocusItemSelectionChange() {
    const selectedArticle = this.inspectionGroupForm.controls["article"].value;
    const isDifferentValueSelection = this.previousSelection?.name !== selectedArticle?.name;

    if (isDifferentValueSelection) {
      this.resetQuantityValidation();
    }
    this.previousSelection = selectedArticle;
  }

  resetQuantityValidation() {
    this.availableStockQuantity = undefined;
    this.updateTotalStockQuantityAndValidate();
    this.getArticleQuantityValidation();
  }

  displayFn(item: InspectionItem): string {
    return item ? `${item.articleIdentifier} ${item.name}` : "";
  }

  allowSelection(option: string): { [className: string]: boolean } {
    return {
      "no-data": option === "",
    };
  }

  loadNextFocusItems() {
    this.searchValue({
      searchText: this.inspectionGroupForm.controls["article"].value,
      inspectionId: this.data.inspectionId ?? "",
      currentPage: this.loadNextClicks++,
    });
  }

  getArticleQuantityValidation(stockQuantity?: boolean) {
    if (this.inspectionGroupForm) {
      const formControls = this.inspectionGroupForm.controls;
      const maxValidator = Validators.max(this.data.availablePieces);

      formControls["article"].setValidators([
        articleQuantityValidator(stockQuantity),
        articleValidatorFn(),
        Validators.required,
      ]);
      formControls["quantity"].setValidators([Validators.required, Validators.min(1), maxValidator]);
      formControls["article"].updateValueAndValidity();
      formControls["quantity"].updateValueAndValidity();
    }
  }

  private updateTotalStockQuantityAndValidate(): void {
    if (this.availableStockQuantity) {
      this.totalStockQuantity = Math.min(this.data.availablePieces, this.availableStockQuantity);
      this.getArticleQuantityValidation(!!this.totalStockQuantity);
    }
  }
}
