import { Component, Input } from '@angular/core';
import { ExportGalleryOverviewDto } from 'src/app/models/export-pdf.model';
import { InspectionFailureReasonsGalleryDto, InspectionResultDetailsDto, InspectionResultGallery } from 'src/app/models/inspection-result';
import { ExportPdfService } from 'src/app/services/export-pdf.service';
import { GalleryService } from 'src/app/services/gallery.service';

@Component({
  selector: 'app-failure-reasons-group-gallery',
  templateUrl: './failure-reasons-group-gallery.component.html'
})

export class FailureReasonsGroupGalleryComponent {
  @Input() inspectionResultDetails!: InspectionResultDetailsDto;
  isLoading = false;
  inspectionFailureReasonsGallery: InspectionFailureReasonsGalleryDto[] = [];

  constructor(private galleryService: GalleryService, private exportPdfService: ExportPdfService) { }

  ngOnInit(): void {
    this.getGallery();
  }

  get hasDataAvailable() {
    return this.inspectionFailureReasonsGallery.length > 0;
  }

  getGallery() {
    this.isLoading = true;
    this.galleryService.getInspectionGalleryGroupedByFailureReason(this.inspectionResultDetails.inspectionId).subscribe({
      next: (data) => {
        this.inspectionFailureReasonsGallery = data;
        this.isLoading = false;
      },
      error: () => (this.isLoading = false),
    });
  }

  downloadAsPdf() {
    const exportGalleryOverview: ExportGalleryOverviewDto = {
      failureReasonsList: this.inspectionFailureReasonsGallery.map((item) => ({
        inspectionGroupIdentifier: Number(item.inspectionItemDetailsGallery[0].inspectionGroupIdentifier),
        inspectionItems: item.inspectionItemDetailsGallery.map((details) => ({
          ...details,
          inspectionItemFailureReasons: details.inspectionItemFailureReasonIds.map((failureReasonId) => ({
            failureReasonId: item.failureReasonId,
            inspectionItemFailureReasonIds: [failureReasonId]
          })),
        }))
      })),
      inspectionResultDetails: this.inspectionResultDetails
    };
    this.exportPdfService.galleryOverviewGeneratePdf(exportGalleryOverview);
  }
}
