import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-download-button-pdf',
  templateUrl: './download-button-pdf.component.html'
})

export class DownloadButtonPdfComponent {
  @Input() hasDataAvailable: boolean = false;
  @Input() downloadAsPdf!: () => void;
}
