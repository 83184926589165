import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BasePage } from "src/app/pages/base-page";
import { ConfirmationDialogComponent } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { InspectionDetails } from "src/app/models/inspection-details";
import { EventBusService } from "src/app/services/event-bus.service";
import { FocusItemsService } from "src/app/services/focus-items.service";
import { InspectionItemsService } from "src/app/services/inspection-items.service";
import { InspectionService } from "src/app/services/inspections.service";
import { ToastService } from "src/app/services/toast.service";
import { FocusItemDialogComponent } from "src/app/components/focus-item-dialog/focus-item-dialog.component";

@Component({
  selector: "app-test-sheet",
  templateUrl: "./test-sheet.page.html",
  styleUrls: ["./test-sheet.page.scss"],
})
export class TestSheetPage extends BasePage implements OnInit {
  inspection?: InspectionDetails;
  isLoading = false;
  totalPieces = 0;
  availablePieces = 0;
  focusItems = 0;
  inspectionId!: string;

  constructor(
    public confirmationDialog: MatDialog,
    private inspectionsService: InspectionService,
    private focusItemsService: FocusItemsService,
    private inspectionItemsService: InspectionItemsService,
    private toastService: ToastService,
    private router: Router,
    protected override route: ActivatedRoute,
    protected override eventBusService: EventBusService
  ) {
    super(route, eventBusService);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.emitBranchId(params);
      this.getInspection(params["id"]);
      this.inspectionId = params['id'];
    });

  }

  confirmDialog(
    title: string,
    question: string,
    name: string,
    action: () => void,
    confirmButtonText: string,
    confirmButtonColor?: string,
  ) {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent, unknown> | null = this.confirmationDialog.open(
      ConfirmationDialogComponent,
      {
        data: { title, question, name, confirmButtonText, confirmButtonColor },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        action();
      }
      dialogRef = null;
    });
  }

  deleteFocusItem(item: { inspectionItemId: string; name: string }) {
    this.confirmDialog(
      "Inspection.DeleteItemTitle",
      "Inspection.DeleteItemDescription",
      item.name,
      () => {
        this.isLoading = true;
        this.focusItemsService.deleteFocusItem(item.inspectionItemId).subscribe(() => {
          this.getInspection(this.inspection?.inspectionId as string);
        });
      },
      "General.Delete",
      "danger"
    );
  }

  deleteItem(item: { inspectionItemId: string; name: string }) {
    this.confirmDialog(
      "Inspection.DeleteItemTitle",
      "Inspection.DeleteItemDescription",
      item.name,
      () => {
        this.isLoading = true;
        this.inspectionItemsService.deleteItem(item.inspectionItemId).subscribe(() => {
          this.getInspection(this.inspection?.inspectionId as string);
        });
      },
      "General.Delete",
      "danger"
    );
  }

  goToConfiguration() {
    this.router.navigateByUrl(`branch/${this.branchId}/configuration-list/${this.inspection?.numberOfResults}`);
  }

  resetConfiguration() {
    this.confirmDialog(
      "TestSheet.ResetConfiguration",
      "Inspection.ResetConfigurationDescription",
      "",
      () => {
        this.isLoading = true;
        this.inspectionsService.resetConfiguration(this.inspection?.inspectionId).subscribe(() => {
          this.getInspection(this.inspection?.inspectionId as string);
        });
      },
      "General.Reset",
      "danger"
    );
  }

  export() {
    this.confirmDialog(
      "TestSheet.ExportTitle",
      "TestSheet.ExportDescription",
      "",
      () => {
        this.isLoading = true;
        this.inspectionsService.finishSetup(this.inspection?.inspectionId).subscribe(() => {
          this.router.navigateByUrl(`branch/${this.branchId}/exported-inspection/${this.inspection?.inspectionId}`);
        });
      },
      "TestSheet.Export",
      "accent"
    );
  }

  openFocusItemDialog() {
    let dialogRef: MatDialogRef<FocusItemDialogComponent, unknown> | null = this.confirmationDialog.open(
      FocusItemDialogComponent,
      {
        data: {
          availablePieces: this.availablePieces,
          inspectionId: this.inspectionId,
        },
      }
    );
    dialogRef.componentInstance.getInspectionEvent.subscribe((result) => {
      if (result) {
        this.getInspection(result);
      }
      dialogRef = null;
    });
  }

  private getInspection(inspectionId: string) {
    this.isLoading = true;
    this.inspectionsService.getInspection(inspectionId).subscribe({
      next: (data) => {
        if (data.isSetupFinished) {
          this.router.navigateByUrl(`branch/${this.branchId}/dashboard`);
        }
        this.inspection = data;
        this.calculateTotalPieces(data);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.toastService.openSnackBar("General.UnknownError");
      },
    });
  }

  private calculateTotalPieces(data: InspectionDetails) {
    this.totalPieces = 0;
    this.focusItems = 0;
    data.inspectionGroups.forEach((group) => {
      this.totalPieces += group.inspectionItems.reduce((accumulator, value) => {
        return accumulator + value.quantity;
      }, 0);
      this.focusItems += group.focusInspectionItems.reduce((accumulator, value) => {
        return accumulator + value.quantity;
      }, 0);
    });
    this.totalPieces += this.focusItems;

    if (this.inspection?.numberOfResults) {
      this.availablePieces = this.inspection.numberOfResults - this.totalPieces;
    }
  }
}
