<app-download-button-pdf
  [hasDataAvailable]="hasDataAvailable"
  [downloadAsPdf]="downloadAsPdf.bind(this)"
></app-download-button-pdf>

<div class="failures-list-container gallery-overview" *ngIf="inspectionItemsGallery">
  <loading *ngIf="isLoading"></loading>
  <div class="failures-list list-container" *ngFor="let inspectionItem of inspectionItemsGallery">
    <h2>{{ inspectionItem.articleIdentifier }} - {{ inspectionItem.name }}</h2>
    <h3>
      {{ "ConfigurationList.InspectionGroup." + inspectionItem.inspectionGroupIdentifier | i18next }}
    </h3>

    <div class="failure-image-container">
      <div *ngFor="let inspectionItemFailureReason of inspectionItem.inspectionItemFailureReasons">
        <h4>
          {{ "InspectionResult.ItemFailureReasons.List." + inspectionItemFailureReason.failureReasonId | i18next }}
        </h4>
        <div *ngFor="let inspectionItemFailureReasonId of inspectionItemFailureReason.inspectionItemFailureReasonIds">
          <app-image-size
            [inspectionItemImageDetails]="{
              inspectionItemFailureReasonId: inspectionItemFailureReasonId,
              failureReasonId: inspectionItemFailureReason.failureReasonId,
              inspectionItemName: inspectionItem.name,
              articleIdentifier: inspectionItem.articleIdentifier
            }"
          ></app-image-size>
        </div>
      </div>
    </div>
  </div>
</div>
