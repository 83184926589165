<section class="test-items-wrap">
  <div class="test-sheet-list">
    <div>
      {{
        "ConfigurationList.InspectionGroup." + piecesData.inspectionGroup.inspectionGroupIdentifier.toString() | i18next
      }}
    </div>
    <div class="mb-2">
      <div *ngFor="let inspectionItem of piecesData.inspectionGroup?.inspectionItems" class="articles-list">
        <div>{{ inspectionItem.articleIdentifier }} {{ inspectionItem.name }}</div>
        <div class="text-center">
          <input readonly value="{{ inspectionItem.quantity || 0 }}" class="small" />
        </div>
        <div class="text-center delete-container">
          <doka-button
            color="clear"
            icon="delete"
            [iconOnly]="true"
            class="button-icon"
            (click)="deleteItem(inspectionItem.inspectionItemId, inspectionItem.name)"
          ></doka-button>
        </div>
      </div>
      <section [ngClass]="{ 'focus-items': hasFocusItems }">
        <div *ngFor="let focusItem of piecesData.inspectionGroup?.focusInspectionItems" class="articles-list">
          <div>
            <span class="new-article">{{ "+" }}</span
            >{{ focusItem.articleIdentifier }} {{ focusItem.name }}
          </div>
          <div class="text-center">
            <input readonly value="{{ focusItem.quantity || 0 }}" class="small" />
          </div>
          <div class="text-center">
            <doka-button
              color="clear"
              icon="delete"
              [iconOnly]="true"
              class="button-icon"
              (click)="deleteFocusItem(focusItem.inspectionItemId, focusItem.name)"
            ></doka-button>
          </div>
        </div>
      </section>
    </div>
  </div>
</section>
