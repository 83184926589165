import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private oauthService: OAuthService,
    private authConfig: AuthConfig,
    public router: Router,
  ) {
  }

  async initAuth() {
    return new Promise<void>((resolveFn, rejectFn) => {
      this.oauthService.configure(this.authConfig);
      this.oauthService.tokenValidationHandler = new JwksValidationHandler();

      this.oauthService.loadDiscoveryDocumentAndLogin().then(
        (isLoggedIn) => {
          if (isLoggedIn) {
            this.oauthService.setupAutomaticSilentRefresh();
            resolveFn();
          } else {
            const urlPath = window.location.pathname;
            sessionStorage.setItem('redirectUrl', urlPath);
            this.oauthService.initImplicitFlow();
            rejectFn();
          }
        },
        (error) => {
          if (error.status === 400) {
            location.reload();
          }
        }
      );
    });
  }

  getAccessToken() {
    return this.oauthService.getAccessToken();
  }

  getCurrentUser() {
    const claims = this.oauthService.getIdentityClaims() as { name: string; preferred_username: string; sub: string };
    return {
      email : claims.preferred_username,
      name : claims.name,
      identityId : claims.sub,
    } as UserModel;
  }

  getUserName() {
    const claims = this.oauthService.getIdentityClaims() as { name: string };
    if (!claims) {
      return null;
    }
    return claims.name;
  }

  logoutSession() {
    this.oauthService.logOut();
  }
}
