<div class="test-details-tab">
  <div class="row mb-6 titles">
    <p></p>
    <p matTooltip="{{ 'InspectionResult.TotalFailureReasons' | i18next }}">
      {{ "InspectionResult.TotalFailureReasons" | i18next }}
    </p>
    <p matTooltip="{{ 'InspectionResult.FailureItems' | i18next }} [{{ 'General.Quantity' | i18next }}.]">
      {{ "InspectionResult.FailureItems" | i18next }} [{{ "General.Quantity" | i18next }}.]
    </p>
    <p matTooltip="{{ 'InspectionResult.FailureItems' | i18next }} [%]">
      {{ "InspectionResult.FailureItems" | i18next }} [%]
    </p>
    <p matTooltip="{{ 'InspectionResult.TestItems' | i18next }} [{{ 'General.Quantity' | i18next }}.]">
      {{ "InspectionResult.TestItems" | i18next }} [{{ "General.Quantity" | i18next }}.]
    </p>
  </div>

  <div class="row totals mb-6">
    <div class="title">{{ "InspectionResult.Total" | i18next }}</div>
    <div class="card-value">{{ inspectionResult.groupViewTotal.numberOfFailureReasons }}</div>
    <div class="card-value">{{ inspectionResult.groupViewTotal.numberOfFailureItems }}</div>
    <div class="card-value">{{ inspectionResult.groupViewTotal.failureItemsPercentage }}%</div>
    <div class="card-value">{{ inspectionResult.groupViewTotal.testItems }}</div>
  </div>

  <div class="section-title mb-2">{{ "InspectionResult.GroupView" | i18next }}</div>
  <div class="row mb-3" *ngFor="let group of inspectionResult.groupViews">
    <div>{{ "ConfigurationList.InspectionGroup." + group.inspectionGroupIdentifier | i18next }}</div>
    <div class="card-value">{{ group.numberOfFailureReasons }}</div>
    <div class="card-value">{{ group.numberOfFailureItems }}</div>
    <div class="card-value">{{ group.failureItemsPercentage }}%</div>
    <div class="card-value">{{ group.testItems }}</div>
  </div>
  <div class="comments-row inspector-comment">
    <div class="section-title">{{ "InspectionResult.InspectorComment" | i18next }}</div>
    <div class="comment-card">{{ inspectionResult.inspectorComment }}</div>
  </div>
  <div class="yard-check-comments">
    <div class="section-title">{{ "InspectionResult.YardCheckComments.Title" | i18next }}</div>
    <app-comments-table [inspectionComments]="inspectionResult.yardCheckComments"></app-comments-table>
  </div>
  <div class="comments-row">
    <div class="flex justify-between">
      <div class="section-title">{{ "InspectionResult.SortedByFailureReason" | i18next }}</div>
      <div>{{ "InspectionResult.NumberOf" | i18next }}</div>
    </div>
    <div class="flex justify-between">
      <div class="section-title">{{ "InspectionResult.SortedByArticle" | i18next }}</div>
      <div>{{ "InspectionResult.NumberOf" | i18next }}</div>
    </div>
    <error-sort-table [groupErrors]="inspectionResult.failuresByType"></error-sort-table>
    <error-sort-table [groupErrors]="inspectionResult.failuresByArticle" [areSortedByArticle]="true"></error-sort-table>
  </div>
</div>
