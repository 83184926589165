
  <div class="filters-container">
    <mat-form-field class="filter-selector">
      <mat-label>{{ "InspectionResult.GroupGallery" | i18next }}</mat-label>
      <mat-select  (selectionChange)="onSelectionChange($event.value)">
        <mat-option [value]="galleryGroupType.GroupByInspectionGroup"> {{ "InspectionResult.GalleryGroupType.GroupByInspectionGroup" | i18next }}</mat-option>
        <mat-option [value]="galleryGroupType.GroupByFailureReason">
          {{ "InspectionResult.GalleryGroupType.GroupByFailureReason" | i18next }}
        </mat-option>
        <mat-option [value]="galleryGroupType.GroupByArticle">
          {{ "InspectionResult.GalleryGroupType.GroupByArticle" | i18next }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="gallerySelectedGroup == galleryGroupType.GroupByInspectionGroup">
    <app-inspection-groups-group-gallery [inspectionResultDetails]="inspectionResultDetails"></app-inspection-groups-group-gallery>
  </div>

  <div *ngIf="gallerySelectedGroup == galleryGroupType.GroupByFailureReason">
    <app-failure-reasons-group-gallery [inspectionResultDetails]="inspectionResultDetails"></app-failure-reasons-group-gallery>
  </div>

  <div *ngIf="gallerySelectedGroup == galleryGroupType.GroupByArticle">
    <app-inspection-items-group-gallery [inspectionResultDetails]="inspectionResultDetails"></app-inspection-items-group-gallery>
  </div>