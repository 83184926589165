import i18next from "i18next";

const abbreviatedMonthDateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
};

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

export function getLocalizedDateFormat(date: string): string {
  const locale = i18next.language === "en" ? "en-GB" : i18next.language;
  return new Date(date).toLocaleDateString(locale, dateFormatOptions);
}

export function getLocalizedAbbreviatedDateFormat(date: string): string {
  const locale = i18next.language === "en" ? "en-GB" : i18next.language;
  return new Date(date).toLocaleDateString(locale, abbreviatedMonthDateFormatOptions);
}