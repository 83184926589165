import { Component, Input } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GalleryService } from "src/app/services/gallery.service";
import { ImageModalComponent } from "../image-modal/image-modal.component";
import { InspectionItemImageDetailsDto } from "src/app/models/inspection-result";

@Component({
  selector: "app-image-size",
  templateUrl: "./image-size.component.html",
  styleUrls: ["./image-size.component.scss"],
})
export class ImageSizeComponent {
  @Input() inspectionItemImageDetails!: InspectionItemImageDetailsDto;
  isLoading: boolean = true;
  sizeOptions = [0, 1, 2];
  selectedSize = 0;

  constructor(public dialog: MatDialog, private galleryService: GalleryService) { }

  openImageModal(itemName: string, failureReasonId: number, inspectionItemFailureReasonId: string) {
    const imageData = this.galleryService.getImage(inspectionItemFailureReasonId);

    if (imageData) {
      let dialogRef: MatDialogRef<ImageModalComponent, unknown> | null = this.dialog.open(ImageModalComponent, {
        data: {
          itemName: itemName,
          failureReasonId: failureReasonId,
          imageData: imageData,
        },
      });

      dialogRef.afterClosed().subscribe(() => {
        dialogRef = null;
      });
    }
  }

  onImageLoad() {
    this.isLoading = false;
  }

  onSizeChange(): void {
      this.isLoading =true;
  }

  getRightImage(inspectionItemFailureReasonId: string) {
    switch (this.selectedSize) {
      case 0:
        return this.galleryService.getResizedImage(inspectionItemFailureReasonId, 200);
      case 1:
        return this.galleryService.getResizedImage(inspectionItemFailureReasonId, 400);
      case 2:
        return this.galleryService.getResizedImage(inspectionItemFailureReasonId, 800);
      default:
        return "";
    }
  }
}