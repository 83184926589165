import { Injectable } from '@angular/core';
import { InspectionModel } from '../models/inspections.model';
import { DateRange } from '@angular/material/datepicker';
import { FiltersDashboardModel } from '../models/filters-dashboard';

@Injectable({
  providedIn: 'root'
})
export class FilterDataDashboardService {
  private auditorsList: string[] = [];
  private statusList: string[] = [];
  private typesList: string[] = [];
  private initialFilters: FiltersDashboardModel = {
    auditorName: "",
    dateOfInspection: {} as DateRange<string>,
    inspectionStatusId: "",
    inspectionTypeId: "",
  };
  private filters: FiltersDashboardModel = this.initialFilters;

  constructor() { }

  getAuditorsList(data: InspectionModel[]): string[] {
    if (this.auditorsList.length === 0) {
      this.auditorsList = Array.from(new Set(data.map(item => item.auditor.name)));
    }
    return this.auditorsList;
  }

  getStatusList(data: InspectionModel[]): string[] {
    if (this.statusList.length === 0) {
      this.statusList = Array.from(new Set(data.map(item => item.inspectionStatusId.toString())));
    }
    return this.statusList;
  }

  getTypesList(data: InspectionModel[]): string[] {
    if (this.typesList.length === 0) {
      this.typesList = Array.from(new Set(data.map(item => item.inspectionTypeId.toString())));
    }
    return this.typesList;
  }

  getFilters() {
    return {
      auditors: this.filters.auditorName,
      dateOfInspection: {
        start: this.filters.dateOfInspection.start,
        end: this.filters.dateOfInspection.end
      } as DateRange<string>,
      status: this.filters.inspectionStatusId,
      types: this.filters.inspectionTypeId
    };
  }

  setFilters(filters: FiltersDashboardModel): void {
    this.filters = filters;
  }

  resetFilters(): void {
    this.filters = this.initialFilters;
  }
}
