import { Component, Input, Output, EventEmitter } from "@angular/core";
import { InspectionGroupConfigurationDto } from "src/app/models/focus-item";

@Component({
  selector: "test-items",
  templateUrl: "./inspection-group.component.html",
  styleUrls: ["./inspection-group.component.scss"],
})
export class InspectionGroupComponent {
  @Input() piecesData!: InspectionGroupConfigurationDto;
  @Output() deleteFocusItemEvent = new EventEmitter<{ inspectionItemId: string; name: string }>();
  @Output() deleteItemEvent = new EventEmitter<{ inspectionItemId: string; name: string }>();

  get hasFocusItems(): boolean {
    return (
      this.piecesData.inspectionGroup.focusInspectionItems &&
      this.piecesData.inspectionGroup.focusInspectionItems.length > 0
    );
  }
  deleteFocusItem(inspectionItemId: string, name: string) {
    this.deleteFocusItemEvent.emit({ inspectionItemId, name });
  }

  deleteItem(inspectionItemId: string, name: string) {
    this.deleteItemEvent.emit({ inspectionItemId, name });
  }
}
