import { Component, Input, OnInit } from '@angular/core';
import { ExportGalleryOverviewDto } from 'src/app/models/export-pdf.model';
import { InspectionItemsGalleryDto, InspectionResultDetailsDto, InspectionResultGallery } from 'src/app/models/inspection-result';
import { ExportPdfService } from 'src/app/services/export-pdf.service';
import { GalleryService } from 'src/app/services/gallery.service';

@Component({
  selector: 'app-inspection-items-group-gallery',
  templateUrl: './inspection-items-group-gallery.component.html'
})
export class InspectionItemsGroupGalleryComponent implements OnInit {
  @Input() inspectionResultDetails!: InspectionResultDetailsDto;
  isLoading = false;
  inspectionItemsGallery: InspectionItemsGalleryDto[] = [];

  constructor(private galleryService: GalleryService, private exportPdfService: ExportPdfService) { }

  ngOnInit(): void {
    this.getGallery();
  }

  get hasDataAvailable() {
    return this.inspectionItemsGallery.length > 0;
  }

  getGallery() {
    this.isLoading = true;
    this.galleryService.getInspectionGalleryGroupedByItem(this.inspectionResultDetails.inspectionId).subscribe({
      next: (data) => {
        this.inspectionItemsGallery = data;
        this.isLoading = false;
      },
      error: () => (this.isLoading = false),
    });
  }

  downloadAsPdf() {
    const exportGalleryOverview: ExportGalleryOverviewDto = {
      failureReasonsList: [{
        inspectionGroupIdentifier: Number(this.inspectionItemsGallery[0].inspectionGroupIdentifier), inspectionItems
          : this.inspectionItemsGallery
      }],
      inspectionResultDetails: this.inspectionResultDetails
    };
    this.exportPdfService.galleryOverviewGeneratePdf(exportGalleryOverview);
  }
}
