import { APP_INITIALIZER, LOCALE_ID } from "@angular/core";
import {
  defaultInterpolationFormat,
  I18NextLoadResult,
  I18NextModule,
  I18NEXT_SERVICE,
  ITranslationService,
} from "angular-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { environment } from "src/environments/environment";
import translationBG from "./locals/bg/translation.json";
import translationCS from "./locals/cs/translation.json";
import translationDA from "./locals/da/translation.json";
import translationDE from "./locals/de/translation.json";
import translationEL from "./locals/el/translation.json";
import translationEN from "./locals/en/translation.json";
import translationEN_US from "./locals/en-US/translation.json";
import translationES from "./locals/es/translation.json";
import translationET from "./locals/et/translation.json";
import translationFI from "./locals/fi/translation.json";
import translationFR from "./locals/fr/translation.json";
import translationHR from "./locals/hr/translation.json";
import translationHU from "./locals/hu/translation.json";
import translationIT from "./locals/it/translation.json";
import translationLT from "./locals/lt/translation.json";
import translationLV from "./locals/lv/translation.json";
import translationNO from "./locals/no/translation.json";
import translationNL from "./locals/nl/translation.json";
import translationPL from "./locals/pl/translation.json";
import translationPT from "./locals/pt/translation.json";
import translationRO from "./locals/ro/translation.json";
import translationSK from "./locals/sk/translation.json";
import translationSL from "./locals/sl/translation.json";
import translationSR from "./locals/sr/translation.json";
import translationSV from "./locals/sv/translation.json";
import translationTR from "./locals/tr/translation.json";
import translationUK from "./locals/uk/translation.json";
import { HttpClient } from "@angular/common/http";

const bundledResources = {
  "bg": {
    translation: translationBG,
  },
  "cs": {
    translation: translationCS,
  },
  "da": {
    translation: translationDA,
  },
  "de": {
    translation: translationDE,
  },
  "el": {
    translation: translationEL,
  },
  "en": {
    translation: translationEN,
  },
  "en-US": {
    translation: translationEN_US,
  },
  "es": {
    translation: translationES,
  },
  "et": {
    translation: translationET,
  },
  "fi": {
    translation: translationFI,
  },
  "fr": {
    translation: translationFR,
  },
  "hr": {
    translation: translationHR,
  },
  "hu": {
    translation: translationHU,
  },
  "it": {
    translation: translationIT,
  },
  "lt": {
    translation: translationLT,
  },
  "lv": {
    translation: translationLV,
  },
  "no": {
    translation: translationNO,
  },
  "nl": {
    translation: translationNL,
  },
  "pl": {
    translation: translationPL,
  },
  "pt": {
    translation: translationPT,
  },
  "ro": {
    translation: translationRO,
  },
  "sk": {
    translation: translationSK,
  },
  "sl": {
    translation: translationSL,
  },
  "sr": {
    translation: translationSR,
  },
  "sv": {
    translation: translationSV,
  },
  "tr": {
    translation: translationTR,
  },
  "uk": {
    translation: translationUK,
  },
};

const getI18nextOptions = (http: HttpClient) => ({
  fallbackLng: "en",
  keySeparator: ".",
  supportedLngs: [
    "bg",
    "cs",
    "da",
    "de",
    "el",
    "en",
    "en-US",
    "es",
    "et",
    "fi",
    "fr",
    "hr",
    "hu",
    "it",
    "lt",
    "lv",
    "no",
    "nl",
    "pl",
    "pt",
    "ro",
    "sk",
    "sl",
    "sr",
    "sv",
    "tr",
    "uk",
  ],
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
  },
  backend: {
    backends: [
      HttpBackend,
      resourcesToBackend(bundledResources)
    ],
    backendOptions: [{
      loadPath: `${environment.translationsApiBaseUrl}/api/translations/${environment.oAuth.clientId}/{{lng}}/translation.json`,
      async request(_, url, __, callback) {
        if (!environment.translationsApiBaseUrl) {
          // Always use bundled translation files for Local env
          // Submitting empty payload will cause i18n to use the next backend (bundled translation files)
          callback(undefined, { status: 200, data: {} });
        }
        else {
          // Load translation files from API
          http.get(url)
            .subscribe({
              next(data) {
                callback(undefined, { status: 200, data });
              },
              error(error) {
                callback(error, { status: 500, data: {} });
              }
            });
        }
      }
    } as HttpBackendOptions]
  }
});

const appInit = (i18next: ITranslationService, http: HttpClient) => {
  return async () => {
    const promise: Promise<I18NextLoadResult> = i18next
      .use(LanguageDetector)
      .use(ChainedBackend)
      .init(getI18nextOptions(http));
    return promise;
  };
};

const localeIdFactory = (i18next: ITranslationService) => {
  return i18next.language;
};

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE, HttpClient],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];
