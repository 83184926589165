<loading *ngIf="isLoading"></loading>
<form class="inspection-dialog search-form" [formGroup]="inspectionGroupForm"  (ngSubmit)="addFocusItem()">
  <h1 mat-dialog-title>{{ "TestSheet.AvailablePieces" | i18next }}</h1>
  <div mat-dialog-content class="form-container">
    <mat-form-field class="search" appearance="fill">
      <input
        class="search-input"
        type="text"
        matInput
        [matAutocomplete]="auto"
        formControlName="article"
        placeholder="{{ 'TestSheet.SearchArticles' | i18next }}"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="onFocusItemSelectionChange()"
      >
      <loading *ngIf="isLoading"></loading>
        <mat-option
          *ngFor="let focusItem of inspectionsResult"
          [value]="focusItem"
          [ngClass]="allowSelection(focusItem.name)"
        >
          {{ focusItem.articleIdentifier }} {{ focusItem.name || "Branch.NoMatch" | i18next }}
        </mat-option>
        <div class="ml-4 my-2"  *ngIf="inspectionsResult?.[0]?.name">
          <button
            mat-raised-button
            color="primary"
            type="submit"
            (click)="loadNextFocusItems()"
            [disabled]="areTotalPiecesLoaded"
          >
        
            {{ "TestSheet.LoadNext" | i18next }}
          </button>
        </div>
      </mat-autocomplete>
      <mat-error
        *ngIf="inspectionGroupForm.controls['article']?.errors?.['invalidArticleQuantity']
         && !inspectionGroupForm.controls['article'].errors?.['required'] 
         && !inspectionGroupForm.controls['article'].errors?.['invalidArticle']"
        class="mt-4"
      >
        {{ "TestSheet.AvailableQuantity" | i18next : { totalQuantity: totalStockQuantity } }}
      </mat-error>
      <mat-error *ngIf="inspectionGroupForm.controls['article'].hasError('required')" class="mt-4"
        >{{ "General.InputRequired" | i18next }}
      </mat-error>
      <mat-error
        *ngIf="!inspectionGroupForm.controls['article'].hasError('required') && inspectionGroupForm.controls['article'].errors?.['invalidArticle']"
        class="mt-4"
      >
        {{ "TestSheet.InvalidArticle" | i18next }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="focus-items-quantity" appearance="fill">
      <input matInput type="number" formControlName="quantity" placeholder="0" />
      <mat-error *ngIf="inspectionGroupForm.controls['quantity'].hasError('required')" class="mt-6"
        >{{ "General.InputRequired" | i18next }}
      </mat-error>
      <mat-error *ngIf="inspectionGroupForm.controls['quantity']?.errors?.['min']" class="mt-6">
        {{ "General.Min" | i18next }} 1
      </mat-error>
      <mat-error
        *ngIf="inspectionGroupForm.controls['quantity']?.errors?.['max'] && !inspectionGroupForm.controls['article']?.errors?.['invalidArticleQuantity']"
        class="mt-6"
      >
        {{ "General.Max" | i18next }}
        {{inspectionGroupForm.controls['quantity'].errors?.['max']?.['max']}}
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="justify-right form-actions">
    <doka-button color="clear" class="main-button" (click)="onClose()"> {{ "General.Cancel" | i18next }}</doka-button>
    <button mat-raised-button color="primary" type="submit" [disabled]="data.availablePieces === 0">
      {{ "General.Submit" | i18next }}
    </button>
  </div>
</form>
