import { AfterViewChecked, Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CreateTestInspectionComponent } from "src/app/components/create-test-inspection/create-test-inspection.component";
import { BasePage } from "src/app/pages/base-page";
import { dateFormatOptions } from "src/app/constants/date-format-options";
import { InspectionModel } from "src/app/models/inspections.model";
import { EventBusService } from "src/app/services/event-bus.service";
import { InspectionService } from "src/app/services/inspections.service";
import { ToastService } from "src/app/services/toast.service";
import { ConfirmationDialogComponent } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { FilterDashboardComponent } from "src/app/components/filter-dashboard/filter-dashboard.component";
import { FiltersDashboardModel } from "src/app/models/filters-dashboard";
import { FilterDataDashboardService } from "src/app/services/filter-data-dashboard-service";

@Component({
  selector: "dashboard",
  templateUrl: "./dashboard.page.html",
  styleUrls: ["./dashboard.page.scss"],
})
export class DashboardPage extends BasePage implements OnInit, AfterViewChecked {
  inspections: InspectionModel[] = [];
  initialInspections: InspectionModel[] = [];
  isLoading = false;
  isAuditor!: boolean | undefined;

  constructor(
    private inspectionsService: InspectionService,
    private toastService: ToastService,
    public createTestInspectionDialog: MatDialog,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    protected override route: ActivatedRoute,
    protected override eventBusService: EventBusService,
    public confirmationDialog: MatDialog,
    public filterDialog: MatDialog,
    private filterDataDashboardService: FilterDataDashboardService
  ) {
    super(route, eventBusService);
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe((params) => {
      this.emitBranchId(params);
      this.getInspections();
    });

    this.checkIsAuditor();
  }

  openFilterDialog(): void {
    let dialogRef: MatDialogRef<FilterDashboardComponent, unknown> | null = this.filterDialog.open(
      FilterDashboardComponent,
      {
        width: "60rem",
        data: this.inspections,
      }
    );
    dialogRef.afterClosed().subscribe((value) => {
      const filters = value as FiltersDashboardModel;
      if (filters) {
        this.inspections = this.initialInspections.filter((inspection: InspectionModel) => {
          const { auditorName, inspectionStatusId, inspectionTypeId, dateOfInspection } = filters;
          const matchesAuditor = !auditorName || inspection.auditor.name === auditorName;
          const matchesStatus = !inspectionStatusId || inspection.inspectionStatusId.toString() === inspectionStatusId;
          const matchesType = !inspectionTypeId || inspection.inspectionTypeId.toString() === inspectionTypeId;
          const matchesDate =
            (!dateOfInspection.start || new Date(inspection.dateOfInspection) >= new Date(dateOfInspection.start)) &&
            (!dateOfInspection.end || new Date(inspection.dateOfInspection) <= new Date(dateOfInspection.end));

          return matchesAuditor && matchesStatus && matchesType && matchesDate;
        });
      }
    });
  }

  resetFilters(): void {
    this.inspections = this.initialInspections;
    this.filterDataDashboardService.resetFilters();
  }

  checkIsAuditor() {
    const userRoles = (sessionStorage.getItem("user_roles") as string).split(",");

    this.isAuditor =
      typeof userRoles === "string"
        ? userRoles === "yardqualitycheck-api.doka.com/yard.auditor"
        : !!userRoles.find((role: string) => role === "yardqualitycheck-api.doka.com/yard.auditor");
  }

  openDialog(): void {
    this.createTestInspectionDialog.open(CreateTestInspectionComponent, {
      width: "60rem",
      data: {
        branchId: this.branchId,
      },
    });
  }

  getDateFormat(date: string) {
    return new Date(date).toLocaleDateString(localStorage.getItem("i18nextLng") || "en", dateFormatOptions);
  }

  goTo(path: string, inspectionId?: string) {
    this.router.navigateByUrl(`branch/${this.branchId}/${path}/${inspectionId}`);
  }

  confirmDialog(
    title: string,
    question: string,
    name: string,
    action: () => void,
    confirmButtonText: string,
    confirmButtonColor?: string
  ) {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent, unknown> | null = this.confirmationDialog.open(
      ConfirmationDialogComponent,
      {
        data: { title, question, name, confirmButtonText, confirmButtonColor },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        action();
      }
      dialogRef = null;
    });
  }

  deleteInspection(inspectionId: string, inspectionDate: string) {
    this.confirmDialog(
      "Inspection.DeleteInpsectionTitle",
      "Inspection.DeleteInpsectionDescription",
      ` ${inspectionDate}?`,
      () => {
        this.isLoading = true;
        this.inspectionsService.deleteInspection(inspectionId).subscribe(() => {
          this.isLoading = false;
          this.getInspections();
        });
      },
      "General.Delete",
      "danger"
    );
  }

  private getInspections() {
    this.isLoading = true;
    this.inspectionsService.getInspections(this.branchId).subscribe({
      next: (data) => {
        this.inspections = data.items;
        this.initialInspections = data.items;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.toastService.openSnackBar("General.UnknownError");
      },
    });
  }
}
